import React from "react"
import Img from "gatsby-image"

import styled from "styled-components"

const TeamBanner = ({ data }) => {
  
  return(
    <StyledBanner>
      <Img fluid={data} style={{ width: `100%`, height: "100%" }} imgStyle={{ objectFit: "cover" }}/>
    </StyledBanner>
  )
}

export default TeamBanner

const StyledBanner = styled.div`
  position: relative;
  margin: 6vw 0 0;
  width: 100%;
  padding-bottom: 7vw;
`